import { Component } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";


let OneSignal;

@Component( {
	selector: "app-root",
	templateUrl: "app.component.html"
} )
export class AppComponent
{

    public oneSignalId: any; // store OneSignalId in localStorage

	
	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar
	)
	{
		this.initializeApp();
	}

	initializeApp ()
	{
		this.platform.ready().then( () =>
		{
			this.statusBar.styleDefault();
			this.splashScreen.hide();
		} );
	}
	ngOnInit() {
		//alert('');
		// OneSignal = window['OneSignal'] || [];
		// console.log("Init OneSignal");
		// OneSignal.push(["init", {
		//   appId: "ef7716c2-095e-4981-abb5-ac2fc51cdb19",
		//   autoRegister: false,
		//   allowLocalhostAsSecureOrigin: true,
		//   notifyButton: {
		// 	enable: false
		//   }
		// }]);

		// console.log('OneSignal Initialized');
        // this.checkIfSubscribed();


	}

	checkIfSubscribed() {
        OneSignal.push(() => {
            /* These examples are all valid */
            OneSignal.isPushNotificationsEnabled((isEnabled) => {
                if (isEnabled) {
                    console.log('Push notifications are enabled!');
                    this.getUserID();
                } else {
                    console.log('Push notifications are not enabled yet.');
                    this.subscribe();
                }
            }, (error) => {
                console.log('Push permission not granted');
            });
        });
	}
	
	getUserID() {
        OneSignal.getUserId().then((userId) => {
            console.log('User ID is', userId);
            this.oneSignalId = userId;
        });
	}
	
	subscribe() {
        OneSignal.push(() => {
            console.log('Register For Push');
            OneSignal.push(['registerForPushNotifications'])
            OneSignal.on('subscriptionChange', (isSubscribed) => {
                console.log('The user\'s subscription state is now:', isSubscribed);
                this.listenForNotification();
                OneSignal.getUserId().then((userId) => {
                    console.log('User ID is', userId);
                    this.oneSignalId = userId;
                    this.updateLocalUserProfile();
                });
            });
        });
	}
	
	listenForNotification() {
        console.log('Initalize Listener');
        OneSignal.on('notificationDisplay', (event) => {
            console.log('OneSignal notification displayed:', event);
            this.listenForNotification();
        });
	}
	
	updateLocalUserProfile() {
        // Store OneSignal ID in your server for sending push notificatios.
    }
}
