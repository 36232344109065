import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [

	{ path: "", redirectTo: "main", pathMatch: "full" },
	{ path: "home", loadChildren: "./pages/home/home.module#HomePageModule" },

	{ path: "area_covered", loadChildren: "./pages/area-covered/area-covered.module#AreaCoveredPageModule" },
	{ path: "notify_me", loadChildren: "./pages/notify-me/notify-me.module#NotifyMePageModule" },
	{ path: "faq", loadChildren: "./pages/faq/faq.module#FaqPageModule" },
	{ path: "faq/:bak", loadChildren: "./pages/faq/faq.module#FaqPageModule" },

	{ path: "menu", loadChildren: "./pages/menu/menu.module#MenuPageModule" },

	{ path: "shopi", loadChildren: "./pages/shopi/shopi.module#ShopiPageModule" },

	{ path: "shop", loadChildren: "./pages/shop/shop.module#ShopPageModule" },

	{ path: "carti", loadChildren: "./pages/carti/carti.module#CartiPageModule" },
	{ path: "cart", loadChildren: "./pages/cart/cart.module#CartPageModule" },

	{ path: "delivery_details", loadChildren: "./pages/delivery-details/delivery-details.module#DeliveryDetailsPageModule" },
	{ path: "delivery_details/:payment_code/:payment_reference/:payment_cancelled/:payment_verify", loadChildren: "./pages/delivery-details/delivery-details.module#DeliveryDetailsPageModule" },

	{ path: "lists", loadChildren: "./pages/lists/lists.module#ListsPageModule" },
	{ path: "list/:grocery_list_id", loadChildren: "./pages/list/list.module#ListPageModule" },

	{ path: "orders", loadChildren: "./pages/orders/orders.module#OrdersPageModule" },
	{ path: "orders/:order_id", loadChildren: "./pages/orders/orders.module#OrdersPageModule" },
	{ path: "order-evaluate/:order_id", loadChildren: "./pages/order-evaluate/order-evaluate.module#OrderEvaluatePageModule" },

	{ path: "register", loadChildren: "./pages/register/register.module#RegisterPageModule" },
	{ path: "login", loadChildren: "./pages/login/login.module#LoginPageModule" },
	{ path: "settings", loadChildren: "./pages/settings/settings.module#SettingsPageModule" },

	{ path: "return/:payment_code/:payment_reference/:payment_cancelled/:payment_verify", loadChildren: "./pages/return/return.module#ReturnPageModule" },
  	{ path: 'main', loadChildren: './pages/main/main.module#MainPageModule' },
  { path: 'auto', loadChildren: './auto/auto.module#AutoPageModule' },
  { path: 'reset-pass', loadChildren: './reset-pass/reset-pass.module#ResetPassPageModule' },
  { path: 'privacy', loadChildren: './privacy/privacy.module#PrivacyPageModule' },
  { path: 'returns', loadChildren: './returns/returns.module#ReturnsPageModule' },
  { path: 'terms', loadChildren: './terms/terms.module#TermsPageModule' },
  { path: 'past-orders', loadChildren: './pages/past-orders/past-orders.module#PastOrdersPageModule' },
  { path: 'past-orders-details', loadChildren: './pages/past-orders-details/past-orders-details.module#PastOrdersDetailsPageModule' },
  { path: 'register-runner', loadChildren: './pages/register-runner/register-runner.module#RegisterRunnerPageModule' },
  { path: 'register-driver', loadChildren: './pages/register-driver/register-driver.module#RegisterDriverPageModule' },
  { path: 'media', loadChildren: './pages/media/media.module#MediaPageModule' },
  { path: 'mediaview', loadChildren: './pages/mediaview/mediaview.module#MediaviewPageModule' }
  
];

@NgModule( {
	imports: [
		RouterModule.forRoot( routes, { preloadingStrategy: PreloadAllModules } )
	],
	exports: [ RouterModule ]
} )

export class AppRoutingModule { }
